import { useEffect } from "react"
import { navigate } from "gatsby"

const Redirect = ({pageContext}) => {
  const { slug } = pageContext;

  useEffect(() => {
    navigate(slug);
  })
  return null;
}

export default Redirect;
